import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import * as classes from "./source_code.module.scss";
import * as themes from "./syntax_themes.module.scss";
import useHighlightedCode from "./use_highlighted_code";

export default function SourceCode({
  code,
  color,
  language,
  hasLineNumbers,
  shouldntWrap,
  ...rest
}) {
  // Highlighted code as HTML string
  const [highlightedCode] = useHighlightedCode(language, code, hasLineNumbers);

  // ToDo: [MW-413] [Gatsby v3] re             import purple theme in syntax highlighting

  const classNames = [classes.sourceCode];

  if (color) {
    classNames.push(themes[`theme_${color}`]);
  }

  if (shouldntWrap) {
    classNames.push(classes.shouldntWrap);
  }

  return (
    <SbEditable content={rest}>
      <pre className={classNames.join(" ")}>
        {/* eslint-disable-next-line react/no-danger */}
        <code dangerouslySetInnerHTML={{ __html: highlightedCode }} />
      </pre>
    </SbEditable>
  );
}

SourceCode.defaultProps = {
  code: "",
  language: null,
  color: null,
  hasLineNumbers: false,
  shouldntWrap: false,
};

SourceCode.propTypes = {
  code: PropTypes.string,
  language: PropTypes.oneOf(["javascript", "json", "bash", "rust"]),
  color: PropTypes.string,
  hasLineNumbers: PropTypes.bool,
  shouldntWrap: PropTypes.bool,
};
